import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const TransparentWithBordersButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#AD3A82 !important",
    border: "1px solid #AD3A82 !important",
    '&:hover': {
      border: "1px solid #AD3A82 !important",
    },
    borderRadius: 4,
    fontSize: "18px !important",
    paddingLeft: "48px !important",
    paddingRight: "48px !important",
    height: 62
  },
}))(Button);