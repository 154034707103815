import React, {useCallback, useEffect, useState} from "react";
import QRCode from "qrcode.react";
import {client} from "../../services/client";
import {useHistory} from "react-router";
import {notification} from "../../components/Notification";


export const Status = () => {
  const [order, setOrder] = useState(null);
  const history = useHistory();

  const getOrder = useCallback(async () => {
    const id = new URLSearchParams(history.location.search).get('id');
    try {
      const response = await client.getOrder(id);
      setOrder(response);
    } catch (e) {
      notification.warning(e.message)
    }
  }, []);

  useEffect(() => {
    getOrder();
  }, [getOrder])

  return (
    <div className="checkout-background">
      <div className="checkout-container">
        <div className="qrcode-container">
          {order && <>
            {order.paymentMethod === "BANK" ?
              <>
                <div>Invoice: {order.invoice}</div>
                <div>Status: {order.status}</div>
              </> :
              <>
              <QRCode value={order && order.cryptoAddress}/>
              <div>
              Open your {order.paymentMethod} wallet and scan the code and
              pay {order.cryptoAmount} {order.paymentMethod} within 5 minutes. Or you can copy the
              wallet address:
              <div>{order.cryptoAddress}</div>
              </div>
              <div>Status: {order.status}</div>
              </>
            }
          </>}
        </div>
      </div>
    </div>
  )
}