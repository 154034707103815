import React from "react";

export const MainLayout = ({children}) => {
  return (
    <>
      <div className="container">
          {children}
      </div>
    </>
  )
}