import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const GreenButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#FFFFFF !important",
    background: "#4EAD74 !important",
    '&:hover': {
      background: "#4EAD74 !important",
    },
    borderRadius: 4,
    fontSize: "18px !important",
    paddingLeft: "48px !important",
    paddingRight: "48px !important",
    height: 62
  },
}))(Button);