import React from "react";
import {PinkButton} from "../../components/AnarButton/PinkButton";
import {TransparentButton} from "../../components/AnarButton/TransparentButton";
import {TransparentWithBordersButton} from "../../components/AnarButton/TransparentWithBordersButton";
import icon1 from '../../images/features/icon1.png';
import icon2 from '../../images/features/icon2.png';
import icon3 from '../../images/features/icon3.png';
import icon4 from '../../images/features/icon4.png';

import icon1Step from '../../images/steps/icon1.png';
import icon2Step from '../../images/steps/icon2.png';
import icon3Step from '../../images/steps/icon3.png';
import arrow from '../../images/steps/arrow.png';
import arrowDown from '../../images/steps/arrow-down.png';
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router";

export const Home = () => {
  const history = useHistory();
  const handleWalletClick = () => {
    window.open('https://wallet.anarchycoin.io/', '_blank');
  }

  return (
    <>
      <div className="main-menu">
        <div className="main-menu-inner">
          <div>
            <div className="logo">ANAR CLOUD MINING</div>
          </div>
          {!isMobile() &&
          <div className="main-menu-list">
            <div>Home</div>
            <div onClick={() => handleWalletClick()}>Get wallet</div>
            <div onClick={() => history.push("/checkout")}>Order here</div>
            <div onClick={() => document.getElementById('how-to').scrollIntoView()}>How to start</div>
          </div>
          }
        </div>
      </div>

      <div className="header">
        <div className="header-container">
          <div>
            <div className="header-left-container">
              <div className="header-description1">
                Start Cloud Mining ANAR Coins. The Next Generation currency.
              </div>
              <div className="header-description2">
                Get your ANAR wallet free and start mining today. You share cloudmining capacity from 1% and upwards.
              </div>
              <div className="header-buttons">
                <PinkButton onClick={() => handleWalletClick()}>Get wallet</PinkButton>
                <TransparentButton onClick={() => history.push("/checkout")}>Order here</TransparentButton>
              </div>
            </div>
          </div>
          {!isMobile() &&
          <div>
            <div className="header-btc"/>
          </div>
          }
        </div>
      </div>
      {isMobile() &&
        <div className="header-btc"/>
      }

      <div className="content-block1">
        <div>
          <div className="caption">Your Unbeatable Advantages</div>
          <div className="description">
            Rent computing power and mine in a cloud with reasonable time investment and no techcinal knowledge required
            to earn ANAR coins.
          </div>
          <div className="content-block1-button">
            <TransparentWithBordersButton onClick={() => history.push("/checkout")}>Order here</TransparentWithBordersButton>
          </div>
        </div>
        <div className="features">
          <div>
            <div className="features-item">
              <div>
                <img alt="ANAR" src={icon1}/>
                <div className="features-caption">Weekly Allocation</div>
              </div>
              <div className="features-description">
                You are awarded minted coins weekly.
              </div>
            </div>
            <div className="features-item features-item-with-margin">
              <div>
                <img alt="ANAR" src={icon2}/>
                <div className="features-caption">Powerful Miners</div>
              </div>
              <div className="features-description">
                High performance ASIC miners.
              </div>
            </div>
          </div>
          <div>
            <div className="features-item">
              <div>
                <img alt="ANAR" src={icon3}/>
                <div className="features-caption">Low Maintenance</div>
              </div>
              <div className="features-description">
                We got the lowest price on electricity.
              </div>
            </div>
            <div className="features-item features-item-with-margin">
              <div>
                <img alt="ANAR" src={icon4}/>
                <div className="features-caption">Secure Facilities</div>
              </div>
              <div className="features-description">
                Server hosting in secure European facilities.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block2" id="how-to">
        <div className="content-block2-container">
          <div>
            <div className="caption">How To Start Cloud Mining ANAR.</div>
            <div className="description">
              Rent from 1% computing power and mine in a cloud with reasonable time investment and no techcinal
              knowledge required.
            </div>
          </div>
          <div className="steps">
            <div>
              <img alt="ANAR" src={icon1Step}/>
              <div>Register a wallet</div>
            </div>
            {isMobile() ? <img className="steps-arrow-down" alt="ANAR" src={arrowDown}/> : <img className="steps-arrow" alt="ANAR" src={arrow}/>}
            <div>
              <img alt="ANAR" src={icon2Step}/>
              <div>Order your capacity</div>
            </div>
            {isMobile() ? <img className="steps-arrow-down" alt="ANAR" src={arrowDown}/> : <img className="steps-arrow" alt="ANAR" src={arrow}/>}
            <div>
              <img alt="ANAR" src={icon3Step}/>
              <div>Receive Payout Weekly</div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block3">
        <div className="content-block3-inner">
          <div>
            <div className="caption">Choose your capacity here</div>
            <div className="description">
              Rent computing power and mine in a cloud with reasonable time investment and no
              techcinal knowledge required
            </div>
          </div>
          <div><PinkButton onClick={() => history.push("/checkout")}>Order here</PinkButton></div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-inner">
          <div className="footer-caption">ANAR CLOUD MINING</div>
          <div className="description">
            Decent Power Marketing LLC
            16192 Costal Highway, Lewes
            Delaware 19958, County of Sussex
            U.S.A.<br/>
            © 2022 - All rights reserved.
          </div>
        </div>
      </div>
    </>
  )
}