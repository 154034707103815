import React, {useState} from "react";
import {
  FormControl,
  FormControlLabel, FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import {isMobile} from "../../utils/checkDevice";
import {client} from "../../services/client";
import {GreenButton} from "../../components/AnarButton/GreenButton";
import {useHistory} from "react-router";
import {notification} from "../../components/Notification";

export const Checkout = () => {
  const [price, setPrice] = useState(50);
  const [capacity, setCapacity] = useState(1);
  const [weeks, setWeeks] = useState(1);
  const [email, setEmail] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [ref, setRef] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("BTC");
  const history = useHistory();

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeWallet = (event) => {
    setWallet(event.target.value);
  };

  const handleChangeRef = (event) => {
    setRef(event.target.value);
  };

  const handleChangeCapacity = (event) => {
    const v = event.target.value;
    setCapacity(v);
    setPrice(v * 50 * weeks);
  };

  const handleChangeWeeks = (event) => {
    const v = event.target.value;
    setWeeks(v);
    setPrice(capacity * 50 * v);
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const createOrder = async () => {
    try {
      const response = await client.createOrder({
        email: email,
        wallet: wallet,
        referral: ref,
        capacity: capacity,
        weeks: weeks,
        paymentMethod: paymentMethod
      });
      history.push("/status?id=" + response.externalId);
    } catch (e) {
      notification.warning(e.message)
    }
  }


  return (
    <>
      <div className="checkout-background">
        <div className="checkout-container">
          <div className="checkout-description">
            To purchase ANAR Cloud Mining capacity and earn up to 200 ANAR per 4 week per percentage of mining power you
            first need to register an ANAR wallet <a href="https://wallet.anarchycoin.io/" target="_blank">here.</a>
          </div>
          <div className="checkout-description">Order information:</div>

          <div className="checkout-form">
            <div className="checkout-field">
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleChangeEmail}
                fullWidth
              />
            </div>
            <div className="checkout-field">
              <TextField
                id="wallet"
                label="ANAR Wallet address"
                variant="outlined"
                value={wallet}
                onChange={handleChangeWallet}
                fullWidth
              />
            </div>
            <div className="checkout-field">
              <TextField
                id="ref"
                label="Referral id"
                variant="outlined"
                value={ref}
                onChange={handleChangeRef}
                fullWidth
              />
            </div>
          </div>

          <div className="checkout-description">Cloud mining price will appear when you have selected mining capacity
            (max 25%) and weeks of mining power (max 52 weeks).
          </div>

          <div className="checkout-field">
            <TextField
              id="price"
              label="Total price to pay"
              variant="outlined"
              disabled
              value={price}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          </div>

          <div className="checkout-select-fields">
            <div className="checkout-field">
              <div>Total Cloud Mining Capacity:</div>
              <Select
                labelId="capacity"
                id="capacity"
                value={capacity}
                label="Total Cloud Mining Capacity"
                onChange={handleChangeCapacity}
                fullWidth
              >
                <MenuItem value={1}>1%</MenuItem>
                <MenuItem value={2}>2%</MenuItem>
                <MenuItem value={3}>3%</MenuItem>
                <MenuItem value={4}>4%</MenuItem>
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={6}>6%</MenuItem>
                <MenuItem value={7}>7%</MenuItem>
                <MenuItem value={8}>8%</MenuItem>
                <MenuItem value={9}>9%</MenuItem>
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={11}>11%</MenuItem>
                <MenuItem value={12}>12%</MenuItem>
                <MenuItem value={13}>13%</MenuItem>
                <MenuItem value={14}>14%</MenuItem>
                <MenuItem value={15}>15%</MenuItem>
                <MenuItem value={16}>16%</MenuItem>
                <MenuItem value={17}>17%</MenuItem>
                <MenuItem value={18}>18%</MenuItem>
                <MenuItem value={19}>19%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={21}>21%</MenuItem>
                <MenuItem value={22}>22%</MenuItem>
                <MenuItem value={23}>23%</MenuItem>
                <MenuItem value={24}>24%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
              </Select>
            </div>
            <div className="checkout-field">
              <div>Weeks of mining power:</div>
              <Select
                labelId="weeks"
                id="weeks"
                value={weeks}
                label="Weeks of mining power"
                onChange={handleChangeWeeks}
                fullWidth
              >

                <MenuItem value={1}>4 weeks</MenuItem>
                <MenuItem value={2}>8 weeks</MenuItem>
                <MenuItem value={3}>12 weeks</MenuItem>
                <MenuItem value={4}>16 weeks</MenuItem>
                <MenuItem value={5}>20 weeks</MenuItem>
                <MenuItem value={6}>24 weeks</MenuItem>
                <MenuItem value={7}>28 weeks</MenuItem>
                <MenuItem value={8}>32 weeks</MenuItem>
                <MenuItem value={9}>36 weeks</MenuItem>
                <MenuItem value={10}>40 weeks</MenuItem>
                <MenuItem value={11}>44 weeks</MenuItem>
                <MenuItem value={12}>48 weeks</MenuItem>
                <MenuItem value={13}>52 weeks</MenuItem>
              </Select>
            </div>
          </div>

          <div className="payment-form">
            <RadioGroup
              row={!isMobile()}
              aria-label="payment"
              defaultValue="btc"
              name="radio-buttons-group"
              className="payment-radio-group"
              onChange={handleChangePaymentMethod}
            >
              <FormControlLabel value="BTC" control={<Radio/>} label="BTC"/>
              <FormControlLabel value="ETH" control={<Radio/>} label="ETH"/>
              <FormControlLabel value="USDT" control={<Radio/>} label="USDT (ERC-20)"/>
              <FormControlLabel value="BANK" control={<Radio/>} label="Bank transfer (EU bank)"/>
            </RadioGroup>
          </div>

          <div className="checkout-buttons">
            <GreenButton onClick={createOrder}>Submit</GreenButton>
          </div>

          <div className="disclaimer">
            DISCLAIMER: Decent Power Marketing LLC provides mining platform and specific ASIC miners for use of
            customers to mine ANAR crypto coin.
            Decent Power Marketing LLC only guarantees agreed mining capacity for each customer. Mining results do vary
            depending on mined coins difficulty level.
            Decent Power Marketing LLC does not guarantee any value nor functionality for mined coin. Coins that are
            mined are mined according ANAR core teams provided specifications.
          </div>
        </div>
      </div>
    </>
  )
}