export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
  },
  ORDERS: {
    CREATE: 'orders',
    GET: (id) => `orders/${id}`,
  },
};
