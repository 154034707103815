import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {MainLayout} from "./components/Layouts/MainLayout/MainLayout";
import {Home} from "./pages/Home/Home";
import {Checkout} from "./pages/Checkout/Checkout";
import {Status} from "./pages/Status/Status";


export const useRoutes = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact={true}>
          <Home/>
        </Route>
        <Route path="/checkout" exact={true}>
          <Checkout/>
        </Route>
        <Route path="/status" exact={true}>
          <Status/>
        </Route>

        <Route path="/">
          <Redirect to={'/'}/>
        </Route>
      </Switch>
    </MainLayout>
  )
}